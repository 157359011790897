const languageData = {
  english: {
    title: "Choi Smog",
    home: "Home",
    aboutUs: "About Us",
    coupon: "Coupon",
    couponDesc: "Coming Soon!",
    contact: "Contact",
    mainTitle: "Welcome to Choi Smog STAR Certified Test Only Station",
    subTitle: "We Smog Check All Vehicles and All DMV Smog Checks",
    caption:
      "With over 20 years of experience in the field, we do our best to make the smog process fast and easy for our customers. No appointments are necessary. ",
    // "Our STAR Station offering DMV Smog Test, smog inspection, Diesel smog check, Registration renewal, Gross polluter certification, Change of ownership smog test and smog check coupons. Call or E-mail us to schedule an appointment. Appointments are available but not necessary.",
    buinessHours: "Buisness Hours",
    monday: "Monday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    closed: "Closed",
    location: "Location",
    locationDesc: "NorthEast corner of Olympic Blvd. And Oxford Ave.",
    servicesTitle: "Choi Smog Services",
    services: [
      "We are State of California licensed smog check, test only.",
      "We smog regular and gross polluters.",
      "We specialize in quick and efficient smogs.",
      "No repairs, and we smog check all Vehicles.",
    ],
    card_1: "Smog Check Coupon",
    card_1_desc: "Save big with our $20 OFF coupon on your next smog test.",
    card_1_btn: "Grab a Coupon",
    card_2: "Licensed Star Station",
    card_2_desc:
      "Established in 2008. With 20 years of experience in the field, we are STAR Certified smog test only station serving Los Angeles, CA.",
    card_2_btn: "See About Us",
    card_3: "Directions",
    card_3_desc:
      "We are located NorthEast corner of Olympic Blvd. And Oxford Ave in Koreatown.",
    card_3_btn: "Directions",
    aboutUsDesc:
      "Established in 2008. With 20 years of experience in the field, our techs conduct inspections with full knowledge of all automotive vehicles' computers and emissions systems to ensure high quality services to all of our customers. We avoid making mistakes many other new stations make, such as not properly warming up before testing or making errors with equipment, both of which can cause a car to fail smog check.",
    contactUs: "Contact us",
    contactUsDesc:
      "Use the form below to send us a message and we'll respond as soon as possible.",
    name: "Name",
    email: "Email Address",
    subject: "Subject",
    message: "Message",
    send: "Send Message",
  },
  korean: {
    title: "최 스모그",
    home: "홈페이지",
    aboutUs: "우리에 대해",
    coupon: "쿠폰",
    couponDesc: "곧 출시됩니다!",
    contact: "문의하기",
    mainTitle:
      "최 스모그 STAR Certified 테스트 스테이션에 오신 것을 환영합니다",
    subTitle: "우리는 모든 차량과 모든 DMV 스모그 검사를 스모그 검사합니다",
    caption:
      "20년 이상의 현장 경험을 바탕으로 고객이 쉽고 빠르게 스모그를 처리할 수 있도록 최선을 다하고 있습니다.",
    buinessHours: "업무 시간",
    monday: "월요일",
    friday: "금요일",
    saturday: "토요일",
    sunday: "일요일",
    closed: "닫은",
    location: "위치",
    locationDesc: "올림픽대로 북동쪽 모퉁이. 그리고 Oxford Ave.",
    servicesTitle: "최 스모그 서비스",
    services: [
      "우리는 캘리포니아 주에서 허가한 스모그 검사, 테스트 전용입니다.",
      "우리는 정기적이고 심각한 오염 물질을 스모그합니다.",
      "우리는 빠르고 효율적인 스모그를 전문으로 합니다.",
      "수리하지 않고 모든 차량을 스모그 점검합니다.",
    ],
    card_1: "스모그 체크 쿠폰",
    card_1_desc: "다음 스모그 테스트에서 $20 할인 쿠폰으로 크게 절약하세요.",
    card_1_btn: "쿠폰 받기",
    card_2: "라이센스 스타 스테이션",
    card_2_desc:
      "2008년에 설립되었습니다. 현장에서 20년의 경험을 가진 우리는 캘리포니아 로스앤젤레스에 서비스를 제공하는 STAR 인증 스모그 테스트 전용 스테이션입니다.",
    card_2_btn: "회사 소개 보기",
    card_3: "지도",
    card_3_desc:
      "우리는 Olympic Blvd의 북동쪽 코너에 위치하고 있습니다. 그리고 한인타운에 있는 Oxford Ave.",
    card_3_btn: "지도 보기",
    aboutUsDesc:
      "2008년에 설립되었습니다. 현장에서 20년의 경험을 가진 당사의 기술진은 모든 자동차의 컴퓨터 및 배기 시스템에 대한 완전한 지식을 바탕으로 검사를 수행하여 모든 고객에게 고품질 서비스를 보장합니다. 우리는 테스트 전에 적절하게 워밍업하지 않거나 장비에 오류를 범하는 것과 같이 다른 많은 새로운 스테이션에서 하는 실수를 피합니다. 두 가지 모두 자동차가 스모그 검사에 실패할 수 있습니다.",
    contactUs: "문의하기",
    contactUsDesc: "메시지를 보내주시면 최대한 빨리 답변해 드리겠습니다.",

    name: "이름",
    email: "이메일 주소",
    subject: "제목",
    message: "메세지",
    send: "메세지 보내",
  },
};

export default languageData;
